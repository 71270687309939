'use strict';

var defaults = require('../core/core.defaults');

var elements = require('../elements/index');

var helpers = require('../helpers/index');

defaults._set('bubble', {
  hover: {
    mode: 'single'
  },
  scales: {
    xAxes: [{
      type: 'linear',
      // bubble should probably use a linear scale by default
      position: 'bottom',
      id: 'x-axis-0' // need an ID so datasets can reference the scale

    }],
    yAxes: [{
      type: 'linear',
      position: 'left',
      id: 'y-axis-0'
    }]
  },
  tooltips: {
    callbacks: {
      title: function title() {
        // Title doesn't make sense for scatter since we format the data as a point
        return '';
      },
      label: function label(item, data) {
        var datasetLabel = data.datasets[item.datasetIndex].label || '';
        var dataPoint = data.datasets[item.datasetIndex].data[item.index];
        return datasetLabel + ': (' + item.xLabel + ', ' + item.yLabel + ', ' + dataPoint.r + ')';
      }
    }
  }
});

module.exports = function (Chart) {
  Chart.controllers.bubble = Chart.DatasetController.extend({
    /**
     * @protected
     */
    dataElementType: elements.Point,

    /**
     * @protected
     */
    update: function update(reset) {
      var me = this;
      var meta = me.getMeta();
      var points = meta.data; // Update Points

      helpers.each(points, function (point, index) {
        me.updateElement(point, index, reset);
      });
    },

    /**
     * @protected
     */
    updateElement: function updateElement(point, index, reset) {
      var me = this;
      var meta = me.getMeta();
      var custom = point.custom || {};
      var xScale = me.getScaleForId(meta.xAxisID);
      var yScale = me.getScaleForId(meta.yAxisID);

      var options = me._resolveElementOptions(point, index);

      var data = me.getDataset().data[index];
      var dsIndex = me.index;
      var x = reset ? xScale.getPixelForDecimal(0.5) : xScale.getPixelForValue(typeof data === 'object' ? data : NaN, index, dsIndex);
      var y = reset ? yScale.getBasePixel() : yScale.getPixelForValue(data, index, dsIndex);
      point._xScale = xScale;
      point._yScale = yScale;
      point._options = options;
      point._datasetIndex = dsIndex;
      point._index = index;
      point._model = {
        backgroundColor: options.backgroundColor,
        borderColor: options.borderColor,
        borderWidth: options.borderWidth,
        hitRadius: options.hitRadius,
        pointStyle: options.pointStyle,
        radius: reset ? 0 : options.radius,
        skip: custom.skip || isNaN(x) || isNaN(y),
        x: x,
        y: y
      };
      point.pivot();
    },

    /**
     * @protected
     */
    setHoverStyle: function setHoverStyle(point) {
      var model = point._model;
      var options = point._options;
      model.backgroundColor = helpers.valueOrDefault(options.hoverBackgroundColor, helpers.getHoverColor(options.backgroundColor));
      model.borderColor = helpers.valueOrDefault(options.hoverBorderColor, helpers.getHoverColor(options.borderColor));
      model.borderWidth = helpers.valueOrDefault(options.hoverBorderWidth, options.borderWidth);
      model.radius = options.radius + options.hoverRadius;
    },

    /**
     * @protected
     */
    removeHoverStyle: function removeHoverStyle(point) {
      var model = point._model;
      var options = point._options;
      model.backgroundColor = options.backgroundColor;
      model.borderColor = options.borderColor;
      model.borderWidth = options.borderWidth;
      model.radius = options.radius;
    },

    /**
     * @private
     */
    _resolveElementOptions: function _resolveElementOptions(point, index) {
      var me = this;
      var chart = me.chart;
      var datasets = chart.data.datasets;
      var dataset = datasets[me.index];
      var custom = point.custom || {};
      var options = chart.options.elements.point;
      var resolve = helpers.options.resolve;
      var data = dataset.data[index];
      var values = {};
      var i, ilen, key; // Scriptable options

      var context = {
        chart: chart,
        dataIndex: index,
        dataset: dataset,
        datasetIndex: me.index
      };
      var keys = ['backgroundColor', 'borderColor', 'borderWidth', 'hoverBackgroundColor', 'hoverBorderColor', 'hoverBorderWidth', 'hoverRadius', 'hitRadius', 'pointStyle'];

      for (i = 0, ilen = keys.length; i < ilen; ++i) {
        key = keys[i];
        values[key] = resolve([custom[key], dataset[key], options[key]], context, index);
      } // Custom radius resolution


      values.radius = resolve([custom.radius, data ? data.r : undefined, dataset.radius, options.radius], context, index);
      return values;
    }
  });
};