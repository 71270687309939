'use strict';

var helpers = require('./helpers.core');
/**
 * @alias Chart.helpers.options
 * @namespace
 */


module.exports = {
  /**
   * Converts the given line height `value` in pixels for a specific font `size`.
   * @param {Number|String} value - The lineHeight to parse (eg. 1.6, '14px', '75%', '1.6em').
   * @param {Number} size - The font size (in pixels) used to resolve relative `value`.
   * @returns {Number} The effective line height in pixels (size * 1.2 if value is invalid).
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
   * @since 2.7.0
   */
  toLineHeight: function toLineHeight(value, size) {
    var matches = ('' + value).match(/^(normal|(\d+(?:\.\d+)?)(px|em|%)?)$/);

    if (!matches || matches[1] === 'normal') {
      return size * 1.2;
    }

    value = +matches[2];

    switch (matches[3]) {
      case 'px':
        return value;

      case '%':
        value /= 100;
        break;

      default:
        break;
    }

    return size * value;
  },

  /**
   * Converts the given value into a padding object with pre-computed width/height.
   * @param {Number|Object} value - If a number, set the value to all TRBL component,
   *  else, if and object, use defined properties and sets undefined ones to 0.
   * @returns {Object} The padding values (top, right, bottom, left, width, height)
   * @since 2.7.0
   */
  toPadding: function toPadding(value) {
    var t, r, b, l;

    if (helpers.isObject(value)) {
      t = +value.top || 0;
      r = +value.right || 0;
      b = +value.bottom || 0;
      l = +value.left || 0;
    } else {
      t = r = b = l = +value || 0;
    }

    return {
      top: t,
      right: r,
      bottom: b,
      left: l,
      height: t + b,
      width: l + r
    };
  },

  /**
   * Evaluates the given `inputs` sequentially and returns the first defined value.
   * @param {Array[]} inputs - An array of values, falling back to the last value.
   * @param {Object} [context] - If defined and the current value is a function, the value
   * is called with `context` as first argument and the result becomes the new input.
   * @param {Number} [index] - If defined and the current value is an array, the value
   * at `index` become the new input.
   * @since 2.7.0
   */
  resolve: function resolve(inputs, context, index) {
    var i, ilen, value;

    for (i = 0, ilen = inputs.length; i < ilen; ++i) {
      value = inputs[i];

      if (value === undefined) {
        continue;
      }

      if (context !== undefined && typeof value === 'function') {
        value = value(context);
      }

      if (index !== undefined && helpers.isArray(value)) {
        value = value[index];
      }

      if (value !== undefined) {
        return value;
      }
    }
  }
};