/**
 * Platform fallback implementation (minimal).
 * @see https://github.com/chartjs/Chart.js/pull/4591#issuecomment-319575939
 */
module.exports = {
  acquireContext: function acquireContext(item) {
    if (item && item.canvas) {
      // Support for any object associated to a canvas (including a context2d)
      item = item.canvas;
    }

    return item && item.getContext('2d') || null;
  }
};