// https://github.com/tc39/proposal-object-getownpropertydescriptors
var $export = require('./_export');

var ownKeys = require('./_own-keys');

var toIObject = require('./_to-iobject');

var gOPD = require('./_object-gopd');

var createProperty = require('./_create-property');

$export($export.S, 'Object', {
  getOwnPropertyDescriptors: function getOwnPropertyDescriptors(object) {
    var O = toIObject(object);
    var getDesc = gOPD.f;
    var keys = ownKeys(O);
    var result = {};
    var i = 0;
    var key, desc;

    while (keys.length > i) {
      desc = getDesc(O, key = keys[i++]);
      if (desc !== undefined) createProperty(result, key, desc);
    }

    return result;
  }
});