// 20.1.2.5 Number.isSafeInteger(number)
var $export = require('./_export');

var isInteger = require('./_is-integer');

var abs = Math.abs;
$export($export.S, 'Number', {
  isSafeInteger: function isSafeInteger(number) {
    return isInteger(number) && abs(number) <= 0x1fffffffffffff;
  }
});