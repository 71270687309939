'use strict';

var defaults = require('../core/core.defaults');

var Element = require('../core/core.element');

defaults._set('global', {
  elements: {
    rectangle: {
      backgroundColor: defaults.global.defaultColor,
      borderColor: defaults.global.defaultColor,
      borderSkipped: 'bottom',
      borderWidth: 0
    }
  }
});

function isVertical(bar) {
  return bar._view.width !== undefined;
}
/**
 * Helper function to get the bounds of the bar regardless of the orientation
 * @param bar {Chart.Element.Rectangle} the bar
 * @return {Bounds} bounds of the bar
 * @private
 */


function getBarBounds(bar) {
  var vm = bar._view;
  var x1, x2, y1, y2;

  if (isVertical(bar)) {
    // vertical
    var halfWidth = vm.width / 2;
    x1 = vm.x - halfWidth;
    x2 = vm.x + halfWidth;
    y1 = Math.min(vm.y, vm.base);
    y2 = Math.max(vm.y, vm.base);
  } else {
    // horizontal bar
    var halfHeight = vm.height / 2;
    x1 = Math.min(vm.x, vm.base);
    x2 = Math.max(vm.x, vm.base);
    y1 = vm.y - halfHeight;
    y2 = vm.y + halfHeight;
  }

  return {
    left: x1,
    top: y1,
    right: x2,
    bottom: y2
  };
}

module.exports = Element.extend({
  draw: function draw() {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped;
    var borderWidth = vm.borderWidth;

    if (!vm.horizontal) {
      // bar
      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      signX = 1;
      signY = bottom > top ? 1 : -1;
      borderSkipped = vm.borderSkipped || 'bottom';
    } else {
      // horizontal bar
      left = vm.base;
      right = vm.x;
      top = vm.y - vm.height / 2;
      bottom = vm.y + vm.height / 2;
      signX = right > left ? 1 : -1;
      signY = 1;
      borderSkipped = vm.borderSkipped || 'left';
    } // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line


    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
      borderWidth = borderWidth > barSize ? barSize : borderWidth;
      var halfStroke = borderWidth / 2; // Adjust borderWidth when bar top position is near vm.base(zero).

      var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
      var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
      var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
      var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0); // not become a vertical line?

      if (borderLeft !== borderRight) {
        top = borderTop;
        bottom = borderBottom;
      } // not become a horizontal line?


      if (borderTop !== borderBottom) {
        left = borderLeft;
        right = borderRight;
      }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth; // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |

    var corners = [[left, bottom], [left, top], [right, top], [right, bottom]]; // Find first (starting) corner with fallback to 'bottom'

    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);

    if (startCorner === -1) {
      startCorner = 0;
    }

    function cornerAt(index) {
      return corners[(startCorner + index) % 4];
    } // Draw rectangle from 'startCorner'


    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);

    for (var i = 1; i < 4; i++) {
      corner = cornerAt(i);
      ctx.lineTo(corner[0], corner[1]);
    }

    ctx.fill();

    if (borderWidth) {
      ctx.stroke();
    }
  },
  height: function height() {
    var vm = this._view;
    return vm.base - vm.y;
  },
  inRange: function inRange(mouseX, mouseY) {
    var inRange = false;

    if (this._view) {
      var bounds = getBarBounds(this);
      inRange = mouseX >= bounds.left && mouseX <= bounds.right && mouseY >= bounds.top && mouseY <= bounds.bottom;
    }

    return inRange;
  },
  inLabelRange: function inLabelRange(mouseX, mouseY) {
    var me = this;

    if (!me._view) {
      return false;
    }

    var inRange = false;
    var bounds = getBarBounds(me);

    if (isVertical(me)) {
      inRange = mouseX >= bounds.left && mouseX <= bounds.right;
    } else {
      inRange = mouseY >= bounds.top && mouseY <= bounds.bottom;
    }

    return inRange;
  },
  inXRange: function inXRange(mouseX) {
    var bounds = getBarBounds(this);
    return mouseX >= bounds.left && mouseX <= bounds.right;
  },
  inYRange: function inYRange(mouseY) {
    var bounds = getBarBounds(this);
    return mouseY >= bounds.top && mouseY <= bounds.bottom;
  },
  getCenterPoint: function getCenterPoint() {
    var vm = this._view;
    var x, y;

    if (isVertical(this)) {
      x = vm.x;
      y = (vm.y + vm.base) / 2;
    } else {
      x = (vm.x + vm.base) / 2;
      y = vm.y;
    }

    return {
      x: x,
      y: y
    };
  },
  getArea: function getArea() {
    var vm = this._view;
    return vm.width * Math.abs(vm.y - vm.base);
  },
  tooltipPosition: function tooltipPosition() {
    var vm = this._view;
    return {
      x: vm.x,
      y: vm.y
    };
  }
});