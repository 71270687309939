'use strict'; // Forced replacement prototype accessors methods

module.exports = require('./_library') || !require('./_fails')(function () {
  var K = Math.random(); // In FF throws only define methods
  // eslint-disable-next-line no-undef, no-useless-call

  __defineSetter__.call(null, K, function () {
    /* empty */
  });

  delete require('./_global')[K];
});