'use strict';

var helpers = require('../helpers/index');

module.exports = {
  /**
   * @private
   */
  _set: function _set(scope, values) {
    return helpers.merge(this[scope] || (this[scope] = {}), values);
  }
};