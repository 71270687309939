'use strict';

var helpers = require('../helpers/index');

var basic = require('./platform.basic');

var dom = require('./platform.dom'); // @TODO Make possible to select another platform at build time.


var implementation = dom._enabled ? dom : basic;
/**
 * @namespace Chart.platform
 * @see https://chartjs.gitbooks.io/proposals/content/Platform.html
 * @since 2.4.0
 */

module.exports = helpers.extend({
  /**
   * @since 2.7.0
   */
  initialize: function initialize() {},

  /**
   * Called at chart construction time, returns a context2d instance implementing
   * the [W3C Canvas 2D Context API standard]{@link https://www.w3.org/TR/2dcontext/}.
   * @param {*} item - The native item from which to acquire context (platform specific)
   * @param {Object} options - The chart options
   * @returns {CanvasRenderingContext2D} context2d instance
   */
  acquireContext: function acquireContext() {},

  /**
   * Called at chart destruction time, releases any resources associated to the context
   * previously returned by the acquireContext() method.
   * @param {CanvasRenderingContext2D} context - The context2d instance
   * @returns {Boolean} true if the method succeeded, else false
   */
  releaseContext: function releaseContext() {},

  /**
   * Registers the specified listener on the given chart.
   * @param {Chart} chart - Chart from which to listen for event
   * @param {String} type - The ({@link IEvent}) type to listen for
   * @param {Function} listener - Receives a notification (an object that implements
   * the {@link IEvent} interface) when an event of the specified type occurs.
   */
  addEventListener: function addEventListener() {},

  /**
   * Removes the specified listener previously registered with addEventListener.
   * @param {Chart} chart -Chart from which to remove the listener
   * @param {String} type - The ({@link IEvent}) type to remove
   * @param {Function} listener - The listener function to remove from the event target.
   */
  removeEventListener: function removeEventListener() {}
}, implementation);
/**
 * @interface IPlatform
 * Allows abstracting platform dependencies away from the chart
 * @borrows Chart.platform.acquireContext as acquireContext
 * @borrows Chart.platform.releaseContext as releaseContext
 * @borrows Chart.platform.addEventListener as addEventListener
 * @borrows Chart.platform.removeEventListener as removeEventListener
 */

/**
 * @interface IEvent
 * @prop {String} type - The event type name, possible values are:
 * 'contextmenu', 'mouseenter', 'mousedown', 'mousemove', 'mouseup', 'mouseout',
 * 'click', 'dblclick', 'keydown', 'keypress', 'keyup' and 'resize'
 * @prop {*} native - The original native event (null for emulated events, e.g. 'resize')
 * @prop {Number} x - The mouse x position, relative to the canvas (null for incompatible events)
 * @prop {Number} y - The mouse y position, relative to the canvas (null for incompatible events)
 */