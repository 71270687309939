'use strict';

var global = require('./_global');

var dP = require('./_object-dp');

var DESCRIPTORS = require('./_descriptors');

var SPECIES = require('./_wks')('species');

module.exports = function (KEY) {
  var C = global[KEY];
  if (DESCRIPTORS && C && !C[SPECIES]) dP.f(C, SPECIES, {
    configurable: true,
    get: function get() {
      return this;
    }
  });
};