// https://github.com/tc39/proposal-promise-finally
'use strict';

var $export = require('./_export');

var core = require('./_core');

var global = require('./_global');

var speciesConstructor = require('./_species-constructor');

var promiseResolve = require('./_promise-resolve');

$export($export.P + $export.R, 'Promise', {
  'finally': function _finally(onFinally) {
    var C = speciesConstructor(this, core.Promise || global.Promise);
    var isFunction = typeof onFinally == 'function';
    return this.then(isFunction ? function (x) {
      return promiseResolve(C, onFinally()).then(function () {
        return x;
      });
    } : onFinally, isFunction ? function (e) {
      return promiseResolve(C, onFinally()).then(function () {
        throw e;
      });
    } : onFinally);
  }
});