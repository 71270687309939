// all object keys, includes non-enumerable and symbols
var gOPN = require('./_object-gopn');

var gOPS = require('./_object-gops');

var anObject = require('./_an-object');

var Reflect = require('./_global').Reflect;

module.exports = Reflect && Reflect.ownKeys || function ownKeys(it) {
  var keys = gOPN.f(anObject(it));
  var getSymbols = gOPS.f;
  return getSymbols ? keys.concat(getSymbols(it)) : keys;
};