'use strict';

var regexpExec = require('./_regexp-exec');

require('./_export')({
  target: 'RegExp',
  proto: true,
  forced: regexpExec !== /./.exec
}, {
  exec: regexpExec
});