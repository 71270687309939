'use strict';

var defaults = require('../core/core.defaults');

defaults._set('scatter', {
  hover: {
    mode: 'single'
  },
  scales: {
    xAxes: [{
      id: 'x-axis-1',
      // need an ID so datasets can reference the scale
      type: 'linear',
      // scatter should not use a category axis
      position: 'bottom'
    }],
    yAxes: [{
      id: 'y-axis-1',
      type: 'linear',
      position: 'left'
    }]
  },
  showLines: false,
  tooltips: {
    callbacks: {
      title: function title() {
        return ''; // doesn't make sense for scatter since data are formatted as a point
      },
      label: function label(item) {
        return '(' + item.xLabel + ', ' + item.yLabel + ')';
      }
    }
  }
});

module.exports = function (Chart) {
  // Scatter charts use line controllers
  Chart.controllers.scatter = Chart.controllers.line;
};