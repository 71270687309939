'use strict';

var defaults = require('../core/core.defaults');

var Element = require('../core/core.element');

var helpers = require('../helpers/index');

var globalDefaults = defaults.global;

defaults._set('global', {
  elements: {
    line: {
      tension: 0.4,
      backgroundColor: globalDefaults.defaultColor,
      borderWidth: 3,
      borderColor: globalDefaults.defaultColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      capBezierPoints: true,
      fill: true // do we fill in the area between the line and its base axis

    }
  }
});

module.exports = Element.extend({
  draw: function draw() {
    var me = this;
    var vm = me._view;
    var ctx = me._chart.ctx;
    var spanGaps = vm.spanGaps;

    var points = me._children.slice(); // clone array


    var globalOptionLineElements = globalDefaults.elements.line;
    var lastDrawnIndex = -1;
    var index, current, previous, currentVM; // If we are looping, adding the first point again

    if (me._loop && points.length) {
      points.push(points[0]);
    }

    ctx.save(); // Stroke Line Options

    ctx.lineCap = vm.borderCapStyle || globalOptionLineElements.borderCapStyle; // IE 9 and 10 do not support line dash

    if (ctx.setLineDash) {
      ctx.setLineDash(vm.borderDash || globalOptionLineElements.borderDash);
    }

    ctx.lineDashOffset = vm.borderDashOffset || globalOptionLineElements.borderDashOffset;
    ctx.lineJoin = vm.borderJoinStyle || globalOptionLineElements.borderJoinStyle;
    ctx.lineWidth = vm.borderWidth || globalOptionLineElements.borderWidth;
    ctx.strokeStyle = vm.borderColor || globalDefaults.defaultColor; // Stroke Line

    ctx.beginPath();
    lastDrawnIndex = -1;

    for (index = 0; index < points.length; ++index) {
      current = points[index];
      previous = helpers.previousItem(points, index);
      currentVM = current._view; // First point moves to it's starting position no matter what

      if (index === 0) {
        if (!currentVM.skip) {
          ctx.moveTo(currentVM.x, currentVM.y);
          lastDrawnIndex = index;
        }
      } else {
        previous = lastDrawnIndex === -1 ? previous : points[lastDrawnIndex];

        if (!currentVM.skip) {
          if (lastDrawnIndex !== index - 1 && !spanGaps || lastDrawnIndex === -1) {
            // There was a gap and this is the first point after the gap
            ctx.moveTo(currentVM.x, currentVM.y);
          } else {
            // Line to next point
            helpers.canvas.lineTo(ctx, previous._view, current._view);
          }

          lastDrawnIndex = index;
        }
      }
    }

    ctx.stroke();
    ctx.restore();
  }
});