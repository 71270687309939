'use strict';

var weak = require('./_collection-weak');

var validate = require('./_validate-collection');

var WEAK_SET = 'WeakSet'; // 23.4 WeakSet Objects

require('./_collection')(WEAK_SET, function (get) {
  return function WeakSet() {
    return get(this, arguments.length > 0 ? arguments[0] : undefined);
  };
}, {
  // 23.4.3.1 WeakSet.prototype.add(value)
  add: function add(value) {
    return weak.def(validate(this, WEAK_SET), value, true);
  }
}, weak, false, true);